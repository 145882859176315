import styled from "styled-components";

const StyledPageOverlay = styled.div`
  flex-grow: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin-bottom: 30px;
  }

  a {
    color: ${props => props.theme.dark_primary};
  }

  p {
    ${props => props.pmb && `margin-bottom: ${props.pmb}px;`}
  }
`;

export default StyledPageOverlay;
