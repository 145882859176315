import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Link } from "react-router-dom";

import InputField from "../InputField";
import { FORM_VALIDATION } from "modules/auth/constants";
import { StyledButton } from "modules/auth/components-styled";

function MFAForm() {
  //
  // cookie hookies
  //
  const [cookies] = useCookies(["XSRF-TOKEN"]);

  //
  // form hooks
  //
  const {
    register,
    formState: { errors },
    setValue,
    control
  } = useForm({ mode: "onChange" });

  //
  // useEffect hooks
  //
  useEffect(() => {
    setValue("_csrf", cookies["XSRF-TOKEN"]);
  }, [cookies, setValue]);

  const isDisabled = Object.keys(errors).length !== 0;

  return (
    // react typically does not use native form actions,
    // we are supposed to use a handler in the onSubmit prop
    // this was done intentionally, to account for BE limitations
    // see LME-538 for details
    <form action="/web/mfa/submit" method="post">
      <div>
        <p>
          A multi-factor authentication code has been sent to you via email or
          authenticator app (if enabled).
        </p>
        <p>
          Please enter the code below to continue to Loblaw Media's Ad Platform.
        </p>
      </div>
      <StyledContainer>
        <InputField
          name="code"
          label="Code"
          control={control}
          size="lg"
          type="number"
          validation={{ ...FORM_VALIDATION.code }}
          isVertical={true}
          errors={errors}
        />
        <StyledButton ml={10} centered disabled={isDisabled}>
          Submit
        </StyledButton>
      </StyledContainer>
      <LogoutLink>
        <Link to="/logout">Logout</Link>
      </LogoutLink>
      <input type="hidden" name="_csrf" {...register("_csrf")} />
    </form>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const LogoutLink = styled.div`
  a {
    align-self: start;
    color: ${props => props.theme.dark_primary};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default MFAForm;
