import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { Context as FormContext } from "modules/auth/context/FormContext";
import Loading from "modules/auth/components/Loading";
import ViewActions from "../ViewActions";
import UpdatePasswordForm from "modules/auth/forms/UpdatePasswordForm";
import PasswordRules from "./PasswordRules";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";
import StyledCustomActionButton from "../StyledCustomActionButton";

function UpdatePasswordViewComponent() {
  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // ref hooks
  //
  const submitRef = useRef(null);

  //
  // constants
  //
  const customActions = (
    <Link to="/profile">
      <StyledCustomActionButton>Return to Profile</StyledCustomActionButton>
    </Link>
  );

  const header = (
    <>
      <h2>
        Update password for {user.first_name} {user.last_name}
      </h2>
      <ViewActions
        submitOnly
        customEditingActions={customActions}
        submitRef={submitRef}
      />
    </>
  );

  return (
    <>
      {user ? (
        <FormViewWrapper
          viewHeader={header}
          form={<UpdatePasswordForm submitRef={submitRef} />}
          viewAppend={<PasswordRules />}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

const UpdatePasswordView = () => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <UpdatePasswordViewComponent />
  </FormContext.Provider>
);

export default UpdatePasswordView;
