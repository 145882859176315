import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";

import Loading from "modules/auth/components/Loading";
import ProfileForm from "modules/auth/forms/ProfileForm";
import { Context as UserContext } from "modules/auth/context/UserContext";
import { Provider as FormContextProvider } from "modules/auth/context/FormContext";
import ViewActions from "../ViewActions";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";
import StyledCustomActionButton from "../StyledCustomActionButton";

function ProfileViewComponent() {
  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // ref hooks for programmatic form submit
  //
  const submitRef = useRef(null);

  //
  // constants
  //
  let passwordAction = (
    <Link to="/profile/password">
      <StyledCustomActionButton>Change Password</StyledCustomActionButton>
    </Link>
  );

  let emailAction = (
    <Link to="/profile/email">
      <StyledCustomActionButton>Change Email</StyledCustomActionButton>
    </Link>
  );

  let backAction = (
    <Link to="/">
      <StyledCustomActionButton>Return to Dashboard</StyledCustomActionButton>
    </Link>
  );

  if (user?.staff_user) {
    passwordAction = null;
    emailAction = null;
  }

  const customActions = (
    <>
      {backAction}
      <Link to="/profile/totp">
        <StyledCustomActionButton>Authenticator App</StyledCustomActionButton>
      </Link>
      {passwordAction}
      {emailAction}
    </>
  );

  const header = (
    <>
      <h2>
        {user?.first_name} {user?.last_name}'s Profile
      </h2>
      <ViewActions
        submitRef={submitRef}
        customViewActions={customActions}
        showEdit={!user?.staff_user}
      />
    </>
  );

  return user ? (
    <FormViewWrapper
      viewHeader={header}
      form={<ProfileForm submitRef={submitRef} defaultValues={user} />}
    />
  ) : (
    <Loading />
  );
}

const ProfileView = () => (
  <FormContextProvider>
    <ProfileViewComponent />
  </FormContextProvider>
);

export default ProfileView;
