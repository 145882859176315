import React from "react";
import objectPath from "object-path";
import { useHistory } from "react-router-dom";

import googleLogo from "modules/auth/assets/google_signin.png";
import { StyledButtonText } from "modules/auth/components-styled/StyledButtonText";

function LoginFormActions({
  usernameIsEvaluated,
  action,
  onBackClick,
  onNextClick,
  challengeAuth,
  usernameInput
}) {
  let googleWindow = null;
  const history = useHistory();
  const language = objectPath
    .get(history, "location.search", "")
    .includes("lg=fr")
    ? "FR"
    : "EN";

  const translation = {
    next: {
      EN: "Next",
      FR: "Suivant"
    },
    back: {
      EN: "Back",
      FR: "Retour"
    },
    submit: {
      EN: "Submit",
      FR: "Envoyez"
    }
  };

  async function onSsoCallback(event) {
    // See oauth-callback.html for message format
    let message = event.data;

    // If Google SSO succeed
    if (message.code) {
      const body = {
        username: usernameInput,
        action: "SSO",
        state: message["state"],
        code: message["code"]
      };

      await challengeAuth(body);
    }
  }

  function loginWithGoogle() {
    window.removeEventListener("message", onSsoCallback);
    if (googleWindow === null || googleWindow.closed) {
      googleWindow = window.open(
        action.url,
        "Google Sign In",
        "toolbar=no,menubar=no,scrollbars=yes,status=yes,width=600,height=700,top=100,left=100"
      );
    }

    window.addEventListener("message", onSsoCallback, false);
  }

  return (
    <div className="form-footer">
      <div>
        {action?.action === "SSO" ? (
          <img
            style={{ cursor: "pointer" }}
            src={googleLogo}
            alt="Google SignIn"
            onClick={loginWithGoogle}
          />
        ) : null}
      </div>
      <div>
        {usernameIsEvaluated ? (
          <button
            className="btn btn-primary rounded-pill customButton text-white"
            type="button"
            onClick={onBackClick}
          >
            <StyledButtonText>{translation.back[language]}</StyledButtonText>
          </button>
        ) : null}

        {!usernameIsEvaluated ? (
          <button
            className="btn btn-primary rounded-pill customButton text-white"
            onClick={onNextClick}
            disabled={
              !usernameInput.includes("@") || usernameInput.trim() === ""
            }
          >
            <StyledButtonText>{translation.next[language]}</StyledButtonText>
          </button>
        ) : null}

        {usernameIsEvaluated && action?.action !== "SSO" ? (
          <button className="btn btn-primary rounded-pill customButton text-white ms-2">
            <StyledButtonText>{translation.submit[language]}</StyledButtonText>
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default LoginFormActions;
