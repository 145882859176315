import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import objectPath from "object-path";

import { appApi } from "modules/auth/api";
import { FORM_VALIDATION } from "modules/auth/constants";
import { StyledButton } from "modules/auth/components-styled";
import { PrependText } from "modules/auth/views/AuthenticatorView/AuthenticatorView";
import InputField from "../InputField";

function AuthenticatorForm({
  authenticatorResponse: { enabled, qr_code },
  submitRef,
  fetchAuthenticatorStatus,
  setSubmitSuccess
}) {
  //
  // form hooks
  //
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    control
  } = useForm();

  //
  // functions
  //
  const handleFormSubmit = async ({ code }) => {
    clearErrors();

    try {
      let apiConfig = {
        method: "POST",
        data: { code: parseInt(code, 10) }
      };

      if (enabled) {
        apiConfig = {
          method: "DELETE",
          data: { code: parseInt(code, 10) }
        };
      }

      await appApi({
        url: "/web/mfa/totp",
        ...apiConfig
      });

      if (apiConfig.method === "POST") {
        setSubmitSuccess(true);
      } else {
        fetchAuthenticatorStatus();
      }
    } catch (err) {
      const validationErrors = objectPath.get(
        err,
        "response.data.validation_errors"
      );

      if (validationErrors) {
        validationErrors.map(e => {
          return setError(e.property, {
            message: e.message
          });
        });
      } else {
        throw err;
      }
    }
  };

  let formContent = (
    <DisableContainer>
      <PrependText mb={50}>
        In order to disable Authenticator for your account and receive MFA codes
        by email in the future, enter the code and click the "Disable
        Authenticator" button below.
      </PrependText>
      <div className="d-flex flex-column w-100 justify-content-center">
        <InputField
          name="code"
          control={control}
          label={"Code"}
          size="lg"
          type="number"
          validation={{ ...FORM_VALIDATION.code }}
          isVertical={true}
          errors={errors}
        />
        <div className="mt-3">
          <StyledButton centered>Disable Authenticator</StyledButton>
        </div>
      </div>
    </DisableContainer>
  );

  if (!enabled && qr_code) {
    formContent = (
      <StyledQRForm>
        <StyledQRContainer>
          <img
            src={qr_code}
            alt="Scan this QR code to receive multi-factor authentication validation code via authenticator app."
          />
        </StyledQRContainer>
        <div className="w-100 justify-content-center d-flex flex-column">
          <div>
            <InputField
              name="code"
              control={control}
              label={"Code"}
              size="lg"
              type="number"
              validation={{ ...FORM_VALIDATION.code }}
              isVertical={true}
              errors={errors}
            />
          </div>
          <div className="mt-3">
            <StyledButton>Enable Authenticator</StyledButton>
          </div>
        </div>
      </StyledQRForm>
    );
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
      {formContent}
      <input ref={submitRef} type="submit" className="hidden" />
    </form>
  );
}

const StyledQRForm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledQRContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

const DisableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default AuthenticatorForm;
