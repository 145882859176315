import React, { useContext } from "react";
import styled from "styled-components";
import objectPath from "object-path";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faSignOut";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { ReactComponent as LinkArrow } from "modules/auth/assets/LinkArrow.svg";
import StyledDivWrapper from "./StyledDivWrapper";
import { StyledButtonTextAppHeader } from "../components-styled/StyledButtonText";
import mediaAisleLogoFR from "modules/auth/assets/Media_Aisle_Logo_mc_colour.png";

function AppHeader() {
  const {
    state: { user }
  } = useContext(UserContext);
  const history = useHistory();
  const language = objectPath
    .get(history, "location.search", "")
    .includes("lg=fr")
    ? "FR"
    : "EN";

  return (
    <StyledHeader>
      <StyledNavWrapper>
        <StyledBrandLogo>
          <Link to={user ? "/" : "/login"}>
            <img
              src={
                language === "FR"
                  ? mediaAisleLogoFR
                  : "https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/loblaw-media/logos/media_aisle_rgb_updated.png"
              }
              alt="Loblaw Authentication Portal"
            />
          </Link>
        </StyledBrandLogo>
        <StyledNavItems>
          <li>
            <Link
              className="btn btn-primary rounded-pill customButton text-white"
              to={{
                pathname:
                  language === "FR"
                    ? "https://www.loblawacceleration.ca/"
                    : "https://www.loblawadvance.ca/"
              }}
              target="_blank"
            >
              <StyledButtonTextAppHeader>
                {language === "FR" ? (
                  <span>
                    Loblawacceleration.ca <LinkArrow className="ms-2" />
                  </span>
                ) : (
                  <span>
                    Loblawadvance.ca <LinkArrow className="ms-2" />
                  </span>
                )}
              </StyledButtonTextAppHeader>
            </Link>
          </li>
          {user && (
            <li className="d-flex align-items-center">
              <Link to="/logout">
                <FontAwesomeIcon icon={faSignOut} /> Logout
              </Link>
            </li>
          )}
        </StyledNavItems>
      </StyledNavWrapper>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  background-image: white;
  min-height: 113px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.nav_top_bg};
  border-bottom: solid;
  border-width: thin;
  border-color: ${props => props.theme.medium_grey_1};
  color: ${props => props.theme.lm_black};
  z-index: 2;
`;

const StyledBrandLogo = styled.div`
  width: 200px;
  img {
    width: 100%;
  }
`;

const StyledNavItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 1.4rem;
  li {
    margin-left: 30px;
    &:hover {
      color: ${props => props.theme.hover_nav};
    }
  }

  a {
    color: ${props => props.theme.lm_black};
    text-decoration: none;
    display: inline-block;
    margin-left: 4px;
    &:hover {
      color: ${props => props.theme.hover_nav};
    }
  }
`;

const StyledNavWrapper = styled(StyledDivWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default AppHeader;
