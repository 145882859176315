import React from "react";
import styled from "styled-components";
import objectPath from "object-path";
import { useHistory } from "react-router-dom";
import { faArrowAltCircleUp } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faArrowAltCircleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import footerTranslation from "./footerTranslation";
import { ReactComponent as LinkArrow } from "modules/auth/assets/LinkArrow.svg";
import footerLogoEN from "modules/auth/assets/Footer-logo.png";
import footerLogoFR from "modules/auth/assets/Footer-logo-FR.png";

function AppFooter() {
  const history = useHistory();
  const inApp =
    !history.location.pathname.includes("login") &&
    !history.location.pathname.includes("logout");
  const language = objectPath
    .get(history, "location.search", "")
    .includes("lg=fr")
    ? "FR"
    : "EN";

  if (inApp) {
    return null;
  }

  const contents = [
    { id: "about", link: "https://www.loblawadvance.ca/about" },
    { id: "solutions", link: "https://www.loblawadvance.ca/solutions" },
    { id: "news", link: "https://www.loblawadvance.ca/insights" },
    { id: "contact", link: "https://www.loblawadvance.ca/contact-us" },
    {
      id: "discover",
      link: "https://www.loblawadvance.ca/discover-mediaaisle"
    },
    {
      id: "mediaAisle",
      link: "/login",
      showArrow: true
    },
    { id: "career", link: "https://www.loblawadvance.ca/job-postings" },
    { id: "adSpecs", link: "https://www.loblawadvance.ca/ad-specs" },
    { id: "terms", link: "https://www.loblaw.ca/en/legal", showArrow: true },
    {
      id: "accessibility",
      link: "https://www.loblaw.ca/en/accessibility/",
      showArrow: true
    },
    {
      id: "privacy",
      link: "https://www.loblaw.ca/en/privacy-policy/",
      showArrow: true
    }
  ];

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <StyledFooter>
      <div className="container">
        <div className="row">
          <div className="col-4 mb-5">
            <img
              alt=""
              src={
                language === "EN"
                  ? footerLogoEN
                  : language === "FR"
                  ? footerLogoFR
                  : null
              }
              width="200"
            />
            <div className="d-flex flex-row mt-5">
              <AnchorTag
                className="me-4"
                active={language === "EN"}
                href="/login"
              >
                EN
              </AnchorTag>{" "}
              |
              <AnchorTag
                className="ms-4"
                active={language === "FR"}
                href="/login?lg=fr"
              >
                FR
              </AnchorTag>
            </div>
          </div>
          <div className="col-7 mb-5">
            <div className="row">
              {contents.map(content => (
                <div className="col-md-4 col-sm-6 mb-4">
                  <AnchorTag
                    href={footerTranslation[`${content.id}Link`][language]}
                  >
                    {footerTranslation[content.id][language]}
                    {content.showArrow && <LinkArrow />}
                  </AnchorTag>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-1 d-flex flex-row p-0"
            style={{ cursor: "pointer", height: "18px" }}
            onClick={() => scrollToTop()}
          >
            {language === "EN" ? (
              <h4 className="me-2">Back To Top</h4>
            ) : language === "FR" ? (
              <h4 className="me-2">Retour en haut de la page</h4>
            ) : (
              ""
            )}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </div>
          <div className="col-4"></div>
          <div className="col-8">{footerTranslation.rights[language]}</div>
        </div>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background: #353535;
  color: #fff;
  padding: 48px 0px 24px 0px;
  font-size: 14px;
`;

const AnchorTag = styled.a`
  color: white;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s ease-in;
  &:hover {
    color: gray;
  }
  text-decoration: ${props => props.active && "underline"};
`;

export default AppFooter;
