import React from "react";

function FormViewWrapper({ viewHeader, form, viewAppend, viewPrepend }) {
  return (
    <div className="view-wrapper">
      <div className="view-header">{viewHeader}</div>
      {viewPrepend}
      <div className="form-wrapper">
        <div className="form-container">{form}</div>
      </div>
      {viewAppend}
    </div>
  );
}

export default FormViewWrapper;
