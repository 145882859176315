import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import InputField from "../InputField";
import { FORM_VALIDATION } from "modules/auth/constants";

function MFAForm(props) {
  const { errors, resendCode, setErrors, control } = props;
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    setErrors({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleResendCode = () => {
    setErrors({});
    resendCode();
    setCounter(60);
  };

  const resendCodeComponent =
    counter > 0 ? (
      <p className="text-muted opacity-75">Resend code ({counter})</p>
    ) : (
      <Link to="#" onClick={handleResendCode}>
        Resend code
      </Link>
    );

  return (
    <>
      <div>
        <p>
          A multi-factor authentication code has been sent to you via email or
          authenticator app (if enabled).
        </p>
        <p>
          Please enter the code below to continue to Loblaw Media's Ad Platform.
        </p>
      </div>
      <StyledContainer>
        <InputField
          name="code"
          control={control}
          size="lg"
          type="number"
          validation={{ ...FORM_VALIDATION.code }}
          isVertical={true}
          errors={errors}
        />
      </StyledContainer>
      <div className="mt-5">{resendCodeComponent}</div>
    </>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 35px;
`;

export default MFAForm;
