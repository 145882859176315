import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { Context as FormContext } from "modules/auth/context/FormContext";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";
import AuthenticatorForm from "modules/auth/forms/AuthenticatorForm";
import Loading from "modules/auth/components/Loading";
import { appApi } from "modules/auth/api";
import PageOverlay from "modules/auth/components/PageOverlay";
import RedirectToLogout from "./RedirectToLogout";
import StyledCustomActionButton from "../StyledCustomActionButton";

function AuthenticatorViewComponent() {
  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // state hooks
  //
  const [authenticatorResponse, setAuthenticatorResponse] = useState(null);
  const [error, setError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  //
  // ref hooks for programmatic form submit
  //
  const submitRef = useRef(null);

  //
  // functions
  //
  const fetchAuthenticatorStatus = useCallback(async () => {
    try {
      const response = await appApi.get("/web/mfa/totp");

      setAuthenticatorResponse(response.data.content);
    } catch (e) {
      setError(e);
    }
  }, [setError, setAuthenticatorResponse]);

  //
  // useEffect hooks
  //
  useEffect(() => {
    fetchAuthenticatorStatus();
  }, [fetchAuthenticatorStatus]);

  let header = (
    <h2>
      Authenticator is{!authenticatorResponse?.enabled ? " not" : ""} enabled
      for {user?.first_name} {user?.last_name}
    </h2>
  );

  let backAction = (
    <Link to="/profile">
      <StyledCustomActionButton>Return to Profile</StyledCustomActionButton>
    </Link>
  );
  if (!authenticatorResponse?.enabled) {
    header = (
      <>
        {header}
        {backAction}
      </>
    );
  }

  const viewPrepend = (
    <>
      <PrependText>
        All users receive periodic multi-factor authentication (MFA)
        verification codes via email by default. Enable Authenticator to receive
        MFA codes via an authenticator app instead of by email. You can find a
        variety of authenticator apps on the Google Play Store or the App Store.
      </PrependText>
      <PrependText mb={50}>
        <strong>Note: </strong>Once enabled, you will be logged out to complete
        the Autheticator activation process.
      </PrependText>
    </>
  );

  if (error) {
    return <PageOverlay />;
  }

  if (submitSuccess) {
    return <RedirectToLogout />;
  }

  return (
    <>
      {user && authenticatorResponse ? (
        <FormViewWrapper
          viewHeader={header}
          form={
            <AuthenticatorForm
              submitRef={submitRef}
              authenticatorResponse={authenticatorResponse}
              fetchAuthenticatorStatus={fetchAuthenticatorStatus}
              setSubmitSuccess={setSubmitSuccess}
            />
          }
          viewPrepend={viewPrepend}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

const AuthenticatorView = () => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <AuthenticatorViewComponent />
  </FormContext.Provider>
);

export const PrependText = styled.p`
  line-height: 3rem;
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : "1em")};
`;

export default AuthenticatorView;
