import React from "react";
import TileLink from "./TileLink";
import { TILE_INFO_MAP } from "modules/auth/constants";
import styled from "styled-components";
import ctaLogo from "modules/auth/assets/icon-dashboard-arrow.svg";

const CardContentSection = ({ apps }) => {
  return (
    <CardContentSectionContainer>
      {apps.map(({ name, identifier, url, external, description }, index) => (
        <TileContainer key={index}>
          <ContentContainer>
            <IconWrapper>
              <img
                src={TILE_INFO_MAP[identifier].logo}
                alt={`${identifier} suite logo`}
                width="200"
              />
            </IconWrapper>
            <TextComponent>
              <TileLink app={{ url, external }}>
                <h3>{name || TILE_INFO_MAP[identifier].name}</h3>
              </TileLink>
              {description || TILE_INFO_MAP[identifier].description}
            </TextComponent>
          </ContentContainer>

          <TileLink app={{ url, external }} width={"60px"}>
            <CtaComponent src={ctaLogo} alt="call to action logo" />
          </TileLink>
        </TileContainer>
      ))}
    </CardContentSectionContainer>
  );
};

const CardContentSectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  gap: 8px 8px;
  @media (max-width: 1344px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1052px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 762px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: stretch;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0000000a;

  background: #fff;
  box-sizing: border-box;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 122px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Secondary-Cloud, #f5f5f5);
`;

const TextComponent = styled.div`
  text-decoration: none;
  color: #353535;
  h3 {
    margin-bottom: 10px;
    color: #353535;

    :hover {
      text-decoration: underline;
    }
  }
`;

const CtaComponent = styled.img`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid ${props => props.theme.blue_jay};

  background: #fff;
  box-sizing: border-box;
  flex-basis: auto;
  :hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }
`;

export default CardContentSection;
