const footerTranslation = {
  about: {
    EN: "About",
    FR: "À propos"
  },
  aboutLink: {
    EN: "https://www.loblawadvance.ca/about",
    FR: "https://www.loblawadvance.ca/sur"
  },
  contact: {
    EN: "Contact",
    FR: "Nous joindre"
  },
  contactLink: {
    EN: "https://www.loblawadvance.ca/contact-us",
    FR: "https://www.loblawadvance.ca/nous-contacter"
  },
  career: {
    EN: "Careers",
    FR: "Carrières"
  },
  careerLink: {
    EN: "https://www.loblawadvance.ca/job-postings",
    FR: "https://www.loblawadvance.ca/job-postings-fr"
  },
  news: {
    EN: "News",
    FR: "Nouvelles"
  },
  newsLink: {
    EN: "https://www.loblawadvance.ca/insights",
    FR: "https://www.loblawadvance.ca/connaissances"
  },
  adSpecs: {
    EN: "Ad Specs",
    FR: "Spécifications des publicités"
  },
  adSpecsLink: {
    EN: "https://www.loblawadvance.ca/ad-specs",
    FR: "https://www.loblawadvance.ca/specifications-publicitaires"
  },
  solutions: {
    EN: "Solutions",
    FR: "Solutions"
  },
  solutionsLink: {
    EN: "https://www.loblawadvance.ca/solutions",
    FR: "https://www.loblawadvance.ca/solutions-fr"
  },
  discover: {
    EN: (
      <span>
        Discover MediaAisle<sup>TM</sup>
      </span>
    ),
    FR: (
      <span>
        Découvrir MediaAisle<sup>MC</sup>
      </span>
    )
  },
  discoverLink: {
    EN: "https://www.loblawadvance.ca/discover-mediaaisle",
    FR: "https://www.loblawadvance.ca/decouvrez-mediaaisle"
  },
  mediaAisle: {
    EN: (
      <span>
        MediaAisle<sup>TM</sup> Login
      </span>
    ),
    FR: (
      <span>
        Connexion à MediaAisle<sup>MC</sup>
      </span>
    )
  },
  mediaAisleLink: {
    EN: "#",
    FR: "#"
  },
  terms: {
    EN: "Terms and Conditions",
    FR: "Conditions et modalités"
  },
  termsLink: {
    EN: "https://www.loblaw.ca/en/legal",
    FR: "https://www.loblaw.ca/fr/legal"
  },
  accessibility: {
    EN: "Accessibility",
    FR: "Accessibilité"
  },
  accessibilityLink: {
    EN: "https://www.loblaw.ca/en/accessibility/",
    FR: "https://www.loblaw.ca/fr/accessibility/"
  },
  privacy: {
    EN: "Privacy",
    FR: "Confidentialité"
  },
  privacyLink: {
    EN: "https://www.loblaw.ca/en/privacy-policy/",
    FR: "https://www.loblaw.ca/fr/privacy-policy/"
  },
  rights: {
    EN: "®/™ Trademarks of Loblaws Inc. ©2023 Loblaws Inc.",
    FR: "© 2022 Loblaws inc. Tous droits réservés."
  }
};

export default footerTranslation;
