import React from "react";
import styled from "styled-components";
import ctaLogo from "modules/auth/assets/icon-get-started.svg";
import { TILE_INFO_MAP } from "modules/auth/constants";
import TileLink from "./TileLink";

const DashboardBanner = ({ tileData }) => {
  const {
    name,
    identifier,
    url,
    external,
    description,
    description2
  } = tileData;

  return (
    <DashboardBannerContainer>
      <div>
        <ImageContainer>
          <img
            src={TILE_INFO_MAP[identifier].logo}
            alt={`${TILE_INFO_MAP[identifier].name} logo`}
          />
        </ImageContainer>

        <div>
          <TileLink app={{ url, external }}>
            <h2>{name || TILE_INFO_MAP[identifier].name}</h2>
          </TileLink>
          <p>{description || TILE_INFO_MAP[identifier].description}</p>

          <span>{description2 || TILE_INFO_MAP[identifier].description2}</span>
        </div>
      </div>
      <TileLink app={{ url, external }}>
        <Cta>
          <span>Get Started</span>
          <img src={ctaLogo} alt="logo" />
        </Cta>
      </TileLink>
    </DashboardBannerContainer>
  );
};

const DashboardBannerContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0000000a;
  background: #fff;
  display: flex;
  padding: 60px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;

  h2 {
    color: #353535;
    padding-top: 16px;
    margin-bottom: 8px;
    :hover {
      text-decoration: underline;
    }
  }

  p {
    color: #353535;
    margin-bottom: 2px;
  }

  span {
    color: #757575;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;

  img {
    position: relative;
    width: 143px;
    height: 42px;
    object-fit: contain;
    object-position: center;
  }
`;

const Cta = styled.button`
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--CTA-Bluejay, #5474ab);
  border: none;
  :hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }

  span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
`;

export default DashboardBanner;
