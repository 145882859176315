import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@loblaw-media/icons/fontawesome/pro-solid-svg-icons/faSpinner";

function Loading({
  message = "Loading",
  containerClass = "loading-container"
}) {
  return (
    <div className={containerClass}>
      <h2>{message}</h2>
      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
    </div>
  );
}

export default Loading;
