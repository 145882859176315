import React, { useState, useEffect } from "react";
import qs from "qs";
import styled from "styled-components";

import LoginForm from "modules/auth/forms/LoginForm";
import loginTranslations from "./loginTranslations";
import { Context as FormContext } from "modules/auth/context/FormContext";
import { LOGIN_VIEW_MESSAGES } from "modules/auth/constants";
import { StyledButtonText } from "modules/auth/components-styled/StyledButtonText";

import heroBannerRt from "modules/auth/assets/hero-banner-rt-element.png";
import heroBannerLt from "modules/auth/assets/hero-banner-lt-element.png";
import promoBannerRt from "modules/auth/assets/promo-banner-rt-element.png";
import promoBannerLt from "modules/auth/assets/promo-banner-lt-element.png";
import contactBannerRt from "modules/auth/assets/contact-banner-rt-element.png";
import contactBannerLt from "modules/auth/assets/contact-banner-lt-element.png";

function LoginView({ location: { search } }) {
  const [message, setMessage] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const language = search.includes("lg=fr") ? "FR" : "EN";

  useEffect(() => {
    const queryParams = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    delete queryParams.lg;
    const message = Object.keys(queryParams)[0];

    if (message) {
      setMessage(message);
    }
  }, [search]);

  return (
    <>
      <BlackContainer
        className="d-flex justify-content-center align-items-center py-5"
        style={{ position: "relative" }}
      >
        <h1
          className="text-white mt-5 p-5 display-1"
          style={{ zIndex: "2", width: "40%" }}
        >
          {loginTranslations.login.textTitle[language]}
        </h1>
        <BorderImgCorner alt="" styledAssignCorner={"bl"} src={heroBannerLt} />

        <LoginContainer
          className="d-flex flex-column"
          style={{ position: "relative" }}
        >
          <BorderImgCorner
            alt=""
            styledTop={-19}
            styledAssignCorner={"tr"}
            src={heroBannerRt}
          />
          <h1 className="text-info">
            {loginTranslations.login.title[language]}
          </h1>
          {apiErrorMessage ? (
            <div className={`login-view-message error`}>{apiErrorMessage}</div>
          ) : (
            message && (
              <div className={`login-view-message ${message}`}>
                {LOGIN_VIEW_MESSAGES[message]}
              </div>
            )
          )}
          <div className="flex-fill" style={{ overflow: "auto", zIndex: 2 }}>
            <FormContext.Provider value={{ state: { edit: true } }}>
              <LoginForm
                setApiErrorMessage={setApiErrorMessage}
                userLabel={loginTranslations.login.email[language]}
                passwordLabel={loginTranslations.login.password[language]}
                buttonLabel={loginTranslations.login.next[language]}
              />
            </FormContext.Provider>
          </div>
        </LoginContainer>
      </BlackContainer>
      <WhiteContainer className="d-flex flex-column justify-content-center align-items-center mx-5 py-3">
        <div className="m-5" style={{ position: "relative" }}>
          <BorderImgCorner
            alt=""
            styledWidth={40}
            styledAssignCorner={"tr"}
            src={promoBannerRt}
          />
          <BorderImgCorner
            alt=""
            styledWidth={40}
            styledAssignCorner={"bl"}
            src={promoBannerLt}
          />
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <h1 className="text-info pt-5">
              {loginTranslations.block1.title[language]}
            </h1>
            <p className="text-center w-75">
              {loginTranslations.block1.sub[language]}
            </p>
            <a
              href={loginTranslations.block1.btnLink[language]}
              className="btn btn-primary btn-lg rounded-pill customButton mb-5"
            >
              <StyledButtonText>
                {loginTranslations.block1.btn[language]}
              </StyledButtonText>
            </a>
          </div>
        </div>
      </WhiteContainer>
      <GreyContainer className="d-flex flex-column justify-content-between align-items-center p-5">
        <div className="w-75 pt-5">
          <div style={{ position: "relative" }}>
            <BorderImgCorner
              alt=""
              styledWidth={25}
              styledAssignCorner={"tr"}
              src={contactBannerRt}
            />
            <BorderImgCorner
              alt=""
              styledWidth={25}
              styledAssignCorner={"bl"}
              src={contactBannerLt}
            />
            <h1 className="p-4 text-dark text-center py-4">
              {loginTranslations.block2.title[language]}
            </h1>
          </div>
          <div className="d-flex justify-content-center pt-3 pb-5">
            <a
              href={loginTranslations.block2.talkBtnLink[language]}
              className="btn btn-primary btn-lg rounded-pill me-5 customButton"
              role="button"
              aria-pressed="true"
            >
              <StyledButtonText>
                {loginTranslations.block2.talkBtn[language]}
              </StyledButtonText>
            </a>
            <a
              href={loginTranslations.block2.visitBtnLink[language]}
              className="btn btn-primary btn-lg rounded-pill customButton"
              role="button"
              aria-pressed="true"
            >
              <StyledButtonText>
                {loginTranslations.block2.visitBtn[language]}
              </StyledButtonText>
            </a>
          </div>
        </div>
      </GreyContainer>
    </>
  );
}

const LoginContainer = styled.div`
  background-color: #ffffff;
  width: 620px;
  height: 454px;
  border-radius: 25px 0 0 25px;
  padding: 25px 40px;
  z-index: 2;
`;

const GreyContainer = styled.div`
  background-color: #f5f5f5;
`;

const WhiteContainer = styled.div`
  background-color: #ffffff;
`;

const BlackContainer = styled.div`
  background-color: #353535;
  height: 596px;
`;

const BorderImgCorner = styled.img`
  position: absolute;
  zindex: 1;

  width: ${props => props.styledWidth && props.styledWidth + `px`};
  ${props =>
    props.styledAssignCorner === "bl" && // bottom left
    `
    bottom: 0px;
    left: 0px;
  `}
  ${props =>
    props.styledAssignCorner === "tr" && // top right
    `
    top: ${props.styledTop ? props.styledTop + "px" : "0px"};
    right: 0px;
  `}
`;

export default LoginView;
