import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  StyledLinkWrapper,
  StyledButton,
  StyledPageOverlay
} from "modules/auth/components-styled";

function PageOverlay({
  messageTitle = "Uh oh, something went wrong",
  messageBody = "Please try refreshing the page, or logging out and logging back in.",
  content = null,
  showActions = true
}) {
  //
  // functions
  //
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <StyledPageOverlay>
      <h3>{messageTitle}</h3>
      {messageBody && <p>{messageBody}</p>}
      {content}
      {showActions && (
        <ButtonContainer>
          <StyledButton onClick={handleRefresh}>Refresh</StyledButton>
          <StyledLinkWrapper ml={10}>
            <Link to="/logout">Logout</Link>
          </StyledLinkWrapper>
        </ButtonContainer>
      )}
    </StyledPageOverlay>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export default PageOverlay;
