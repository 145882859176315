import React from "react";
import styled from "styled-components";
import CardContentSection from "./CardContentSection";
import DashboardBanner from "./DashboardBanner";

const DashboardApplications = ({ apps }) => {
  const hmpTile = apps.find(app => app.identifier === "hmp");
  const nonHmpApps = apps.filter(app => app.identifier !== "hmp");

  return (
    <DashboardContainer>
      {hmpTile && <DashboardBanner tileData={hmpTile} />}
      {nonHmpApps.length > 0 && <CardContentSection apps={nonHmpApps} />}
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 247, 250, 60%);
  padding: 21px 91px;
  gap: 8px;
`;

export default DashboardApplications;
