import React from "react";

import { Context as FormContext } from "modules/auth/context/FormContext";
import ForgotPasswordForm from "modules/auth/forms/ForgotPasswordForm";

function ForgotPasswordViewComponent({ location: { state = {} } }) {
  return (
    <div className="form-wrapper login-form-wrapper">
      <div className="form-container login-form-container">
        <h3>Forgot Password?</h3>
        <ForgotPasswordForm defaultValues={state} />
      </div>
    </div>
  );
}

const ForgotPasswordView = props => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <ForgotPasswordViewComponent {...props} />
  </FormContext.Provider>
);

export default ForgotPasswordView;
