import React, { useContext } from "react";
import styled from "styled-components";

import { Context as FormContext } from "modules/auth/context/FormContext";
import { StyledFormButton } from "modules/auth/components-styled";

function ViewActions({
  submitRef,
  customEditingActions,
  customViewActions,
  submitOnly = false,
  showEdit = true,
  submitLabel = "Submit"
}) {
  //
  // useContext hooks
  //
  const {
    state: { edit },
    setEdit
  } = useContext(FormContext);

  //
  // functions
  //
  const submitForm = () => submitRef.current.click();

  const handleKeyPress = (e, cb, ...args) => {
    const key = e.key || e.which;
    if (key === "Enter" || key === 13) {
      cb(...args);
    }
  };

  return (
    <ViewActionsContainer>
      {edit ? (
        <>
          {customEditingActions}

          <StyledFormButton
            onClick={submitForm}
            tabIndex="0"
            onKeyPress={e => handleKeyPress(e, submitForm)}
          >
            {submitLabel}
          </StyledFormButton>

          {!submitOnly && (
            <StyledCancelButton
              onClick={() => setEdit(false)}
              onKeyPress={e => handleKeyPress(e, setEdit, false)}
              tabIndex="0"
            >
              Cancel
            </StyledCancelButton>
          )}
        </>
      ) : (
        <>
          {customViewActions}
          {showEdit && (
            <StyledFormButton
              onClick={() => setEdit(true)}
              tabIndex="0"
              onKeyPress={e => handleKeyPress(e, setEdit, true)}
            >
              Edit
            </StyledFormButton>
          )}
        </>
      )}
    </ViewActionsContainer>
  );
}

const ViewActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(StyledFormButton)`
  margin-left: 5px;
  color: ${props => props.theme.lm_red};
  background-color: transparent;
  border: 1px solid ${props => props.theme.lm_red};

  &:hover {
    background-color: ${props => props.theme.lm_red};
    color: white;
    cursor: pointer;
  }
`;

export default ViewActions;
