import styled from "styled-components";

const StyledLinkWrapper = styled.div`
  && {
    margin-left: ${props => (props.ml ? `${props.ml}px` : 0)};
    a {
      padding: 8px 20px;
      color: ${props => props.theme.secondary};
      background-color: ${props => props.theme.blue_jay};
      border: ${props => `1px solid ${props.theme.blue_jay}`};
      border-radius: 3px;
      display: inline-block;
      text-decoration: none;

      &:hover {
        background-color: transparent;
        color: ${props => props.theme.blue_jay};
        cursor: pointer;
      }
    }
  }
`;

export default StyledLinkWrapper;
