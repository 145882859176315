import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { BaseLayout, AppHeader, AppFooter } from "modules/auth/layout";
import {
  ForgotPasswordView,
  LoginView,
  LogoutView,
  NewPasswordView,
  MFAView
} from "modules/auth/views";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

function Routes() {
  return (
    <BaseLayout header={<AppHeader />} footer={<AppFooter />}>
      <Switch>
        <Route exact path="/login" component={LoginView} />

        <Route exact path="/login/forgot" component={ForgotPasswordView} />

        <Route
          exact
          path="/login/reset"
          render={routeProps => (
            <NewPasswordView view="reset" {...routeProps} />
          )}
        />

        <Route
          exact
          path="/login/register"
          render={routeProps => (
            <NewPasswordView view="register" {...routeProps} />
          )}
        />

        <Route exact path="/login/mfa" component={MFAView} />

        <Route exact path="/logout" component={LogoutView} />

        <Route
          exact
          path={["/", "/profile", "/profile/**"]}
          component={AuthenticatedRoutes}
        />

        <Redirect to="/" />
      </Switch>
    </BaseLayout>
  );
}

export default Routes;
