const lm_black = "rgb(0, 0, 0)";
const lm_red = "rgb(225, 37, 27)";
const lm_pink = "rgb(252, 157, 135)";
const lm_teal = "#00A39B";
const lm_dark_teal = "#004C57";
const lm_dark_grey = "rgb(152, 152, 154)";
const lm_grey = "rgb(200, 200, 200)";
const blue_jay = "#5474AB";
// eslint-disable-next-line
const blue_jay_dark = "rgb(31, 69, 136)";

const theme = {
  primary: lm_teal,
  dark_primary: lm_dark_teal,
  secondary: "#f5f7fa",
  font_primary: "#656565",
  font_light: "#ffffff",
  body_bg: lm_grey,
  disabled_dark: "rgb(147, 183, 189)",
  hover_nav: lm_teal,
  tile_shadow: lm_teal,
  lm_black,
  lm_red,
  lm_pink,
  lm_dark_grey,
  nav_top_bg: "#fff",
  medium_grey_1: "#c5c5c5",
  blue_jay
};

export default theme;
